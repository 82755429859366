<template>
  <div>
    <div class="w-auto" v-if="loading">
      <clip-loader :loading="loading" color="primary"></clip-loader>
    </div>

    <div v-else class="mt-6">
      <vs-row vs-w="12">
        <vs-col
          vs-justify="center"
          vs-align="center"
          vs-lg="12"
          vs-sm="12"
          vs-xs="12"
          class="mb-6"
        >
          <div class="w-full">
            <span>Objet*</span>
            <multiselect
              v-model="variableGlobalFormOject"
              :options="allMailVariableList"
              class="mb-4 mt-2"
              id="text"
              placeholder="--Insérer une variable de personnalisation--"
              label="label"
              track-by="label"
              :selectLabel="global.selectLabel"
              :selectedLabel="global.selectedLabel"
              :deselectLabel="global.deselectLabel"
            >
            </multiselect>
          </div>

          <vs-input
            class="inputx w-full mb-2 mt-2"
            placeholder=""
            v-model="form.object"
            id="texto"
            @change="onEditorChangeFormObjet($event)"
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="Objet"
          />
          <div class="aab-vie-error-msg">{{ errors.first("Objet") }}</div>
        </vs-col>
        <vs-col
          vs-justify="left"
          vs-align="left"
          vs-lg="12"
          vs-sm="12"
          vs-xs="12"
          class="mb-6"
        >
          <div class="mb-4">
            <span>Message*</span>
          </div>

          <div class="w-full">
            <multiselect
              v-model="variableGlobalForm"
              :options="allMailVariableList"
              class="mb-4"
              placeholder="--Insérer une variable de personnalisation--"
              label="label"
              track-by="label"
              :selectLabel="global.selectLabel"
              :selectedLabel="global.selectedLabel"
              :deselectLabel="global.deselectLabel"
            >
            </multiselect>

            <quill-editor
              @focus="onEditorFocusForm($event)"
              @change="onEditorChangeForm($event)"
              v-model="form.message"
            >
            </quill-editor>
          </div>
        </vs-col>
        <vs-col
          vs-justify="center"
          vs-align="center"
          vs-lg="12"
          vs-sm="12"
          vs-xs="12"
          class="mb-6"
        >
          <div
            class="w-full"
            style="
              display: flex;
              align-items: flex-end;
              justify-content: space-between;
            "
          >
            <div>
              <div>
                <div class="mb-4 ml-2">
                  <span>Fichier(s)</span>
                </div>
                <div>
                  <div uk-form-custom class="ebsi-upload-file">
                    <input
                      multiple
                      type="file"
                      :value="resetFilesUpload"
                      v-on:change="checkUploadFile"
                    />
                    <button
                      style="cursor: pointer"
                      class="uk-button uk-button-default"
                      type="button"
                      tabindex="-1"
                    >
                      Joindre des fichiers
                    </button>
                  </div>
                </div>
              </div>

              <div class="aab-vie-error-msg" v-show="formError">
                Erreur lors du chargement du fichier
              </div>
            </div>
          </div>

          <div class="mt-6 mb-3" style="display: flex; flex-direction: column">
            <div
              v-for="(file, index) in form.filesname"
              :key="index"
              class="center"
            >
              <div class="flex">
                {{ index + 1 }})

                <div class="pl-2" v-if="file.filename && file.name">
                  <b class="pl-2 cursor-pointer" @click="checkViewFile(file)">{{
                    subString(file.name)
                  }}</b>
                </div>
                <div class="pl-2" v-else>
                  <b>{{ subString(file) }}</b>
                </div>

                <vx-tooltip color="primary" text="Supprimer">
                  <div class="ml-8 cursor-pointer" @click="removeFile(index)">
                    <feather-icon
                      icon="Trash2Icon"
                      svgClasses="w-6 h-6"
                    ></feather-icon>
                  </div>
                </vx-tooltip>
              </div>
            </div>
          </div>
        </vs-col>
      </vs-row>
    </div>

    <div class="mt-8">
      <div class="inline-flex">
        <div>
          <vs-button
            class="w-full sm:w-auto"
            :disabled="!validateFormAddMail || loadingEnabledAvenant"
            @click="enabledAvenant"
            >Activer</vs-button
          >
          <vs-button
            @click="exitEnabled"
            class="w-full sm:w-auto ml-4"
            type="border"
          >
            Annuler
          </vs-button>
        </div>

        <div class="ml-2">
          <clip-loader
            :loading="loadingEnabledAvenant"
            color="primary"
          ></clip-loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mixin } from "@/mixins/mixin";
import { en, fr } from "vuejs-datepicker/dist/locale";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import global from "@/config/global";
import Multiselect from "vue-multiselect";

export default {
  name: "aab-vie-subscribe-enabled-avenant",
  data() {
    return {
      cursorPositionObject: 0,
      formTakeOjet: {},
      variableGlobalFormOject: "",
      cursorPositionForm: 0,
      quillEditorForm: {},
      variableGlobalForm: "",
      form: {
        message: "",
        object:
          "{{Civilité}} {{Nom}}, activation de votre contrat {{Produit}} - {{Ref_Contrat}}",
        filesname: [],
      },
      loading: false,
      loadingEnabledAvenant: false,
      fileData: [],
      uploadFile: false,
      formData: [],
      fileDataUp: {},
      resetFilesUpload: "",
      displayFileName: false,
      formError: false,
      error: false,
      errorMsg: "",
    };
  },
  computed: {
    ...mapGetters("general", ["allMailVariableText", "allMailVariableList"]),
    ...mapGetters("suscribe", ["currentSuscribeData"]),
    validateFormAddMail() {
      return (
        !this.errors.any() && this.form.object != "" && this.form.message != ""
      );
    },
  },
  props: {
    crrModuleName: String,
    item: Object,
  },
  created() {
    //add lang in validation fields
    Validator.localize("fr", VeeValidateFR);
    this.global = global;
  },

  components: {
    quillEditor,
    ClipLoader,
    Multiselect,
  },
  methods: {
    ...mapActions("suscribe", ["applyPutAvenant", "applySentProject"]),

    ...mapActions("general", [
      "applyUploadFile",
      "applyGenerateCertificat",
      "applyGenerateCotisation",
      "applyGenerateCotisationSolde",
    ]),

    exitEnabled() {
      this.$emit("onDisplayAvenant", false);
    },

    acceptAlert(data) {
      let newdata = { ...data };
      this.$vs.notify(newdata);
    },

    /* Form Objet */
    getInputSelection(el) {
      let start = 0,
        end = 0,
        normalizedValue,
        range,
        textInputRange,
        len,
        endRange;

      if (
        typeof el.selectionStart == "number" &&
        typeof el.selectionEnd == "number"
      ) {
        start = el.selectionStart;
        end = el.selectionEnd;
      } else {
        range = document.selection.createRange();

        if (range && range.parentElement() == el) {
          len = el.value.length;
          normalizedValue = el.value.replace(/\r\n/g, "\n");

          textInputRange = el.createTextRange();
          textInputRange.moveToBookmark(range.getBookmark());

          endRange = el.createTextRange();
          endRange.collapse(false);

          if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
            start = end = len;
          } else {
            start = -textInputRange.moveStart("character", -len);
            start += normalizedValue.slice(0, start).split("\n").length - 1;

            if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
              end = len;
            } else {
              end = -textInputRange.moveEnd("character", -len);
              end += normalizedValue.slice(0, end).split("\n").length - 1;
            }
          }
        }
      }

      return {
        start: start,
        end: end,
      };
    },

    onEditorChangeFormObjet(position) {
      let input = document.getElementById("texto");
      input.focus();
      this.cursorPositionObject = this.getInputSelection(input).start;
      this.formTakeOjet = position;
    },
    insertTextFormObjet(data) {
      this.form.object = [
        this.form.object.slice(0, this.cursorPositionObject),
        `${data} `,
        this.form.object.slice(this.cursorPositionObject),
      ].join("");
      this.cursorPositionObject = this.form.object.length;
    },
    /* End Form Objet */

    /* Form*/
    onEditorChangeForm(quill) {
      this.cursorPositionForm = quill.quill.getSelection(quill).index;
      this.quillEditorForm = quill.quill;
    },
    onEditorFocusForm(quill) {
      this.cursorPositionForm = quill.getSelection();
      this.quillEditorForm = quill;
    },
    insertTextForm(data) {
      this.quillEditorForm.insertText(this.cursorPositionForm, `${data} `);
      this.cursorPositionForm = this.form.message.length - 7;
    },
    /* End Form*/

    async uploadedFiles() {
      let _this = this;
      let productPromises = [];
      this.formData.forEach(function (ele, index) {
        productPromises.push(
          (async function (ele) {
            const res = await _this.applyUploadFile(ele);
            _this.fileData.push({
              filename: res.data[0].uploadedName,
              name: res.data[0].filename,
            });
          })(ele)
        );
      });
      await Promise.all(productPromises);
    },
    async enabledAvenant() {
      this.loadingEnabledAvenant = true;

      if (this.form.filesname.length > 0) {
        this.uploadedFiles()
          .then((res) => {
            this.acceptAlert(
              mixin.methods.message("subscription", "file").success
            );

            this.fetchApplySendEnableAvenant();
          })
          .catch((err) => {
            console.log(err);
            this.acceptAlert(
              mixin.methods.message("subscription", "file").error
            );

            this.fetchApplySendEnableAvenant();
          });
      } else {
        this.fetchApplySendEnableAvenant();
      }

      await this.applyPutAvenant({
        avenantId: this.item.id,
        status: "active",
      });
    },
    async fetchApplySendEnableAvenant() {
      if (!this.validateFormAddMail) return false;
      console.log("this.currentSuscribeData", this.currentSuscribeData);

      // Cotisation Soldé + Certificat
      let gCotisationSolde =  await this.applyGenerateCotisationSolde({ projectId: this.currentSuscribeData.id, avenantId: this.item.id })
      let gCertificat =  await this.applyGenerateCertificat({ projectId: this.currentSuscribeData.id, avenantId: this.item.id })

      let filesname = [
        {
          name: gCotisationSolde.data.docClient.name+".pdf", //gCotisationSolde.data.docClient.name+".pdf",
          filename: gCotisationSolde.data.currPdfName,
        },
        {
          name: gCertificat.data.docClient.name+".pdf", //gCertificat.data.docClient.name+".pdf",
          filename: gCertificat.data.currPdfName,
        },
        ...this.fileData,
      ];

      let data = {
        ...this.form,
        filesname,
        subscriberId: this.currentSuscribeData.subscription.subscriber.id,
        projectId: this.currentSuscribeData.id,
        brokerId: this.currentSuscribeData.broker
          ? this.currentSuscribeData.broker.id
          : "",
      };

      let dataPutAvenant = {
        avenantId: this.item.id,
        status: "active",
        filesname,
      };

      try {
        await this.applyPutAvenant(dataPutAvenant);

        await this.applySentProject(data).then((res) => {
          this.acceptAlert(
            mixin.methods.message("subscription", "activeAvenant").success
          );

          this.loadingEnabledAvenant = false;
          this.uploadFile = true;
          this.form = {
            message: "",
            object:
              "{{Civilité}} {{Nom}}, activation de votre contrat {{Produit}} - {{Ref_Contrat}}",
            filesname: [],
          };
          this.fileData = [];
          this.exitEnabled();
        });
      } catch (err) {
        this.loadingEnabledAvenant = false;
        this.error = true;
        this.errorMsg = "";
      }
    },

    //fichier
    checkUploadFile(evt) {
      const evtLength = evt.target.files.length;
      let allFilesChecked = [];
      let formDataLength = this.formData.length;
      for (let i = 0; i < evtLength; i++) {
        let formDataTmp = new FormData();
        formDataTmp.append("uri", evt.target.files[i]);
        this.formData[formDataLength + 1] = formDataTmp;
        this.formError = false;
        allFilesChecked.push(evt.target.files[i].name);
        formDataLength++;
      }
      if (this.form.filesname) {
        this.form.filesname = [...this.form.filesname, ...allFilesChecked];
      } else {
        this.form.filesname = [...allFilesChecked];
      }
      this.uploadFile = true;
    },

    removeFile(data) {
      this.formData.splice(data, 1);
      this.form.filesname.splice(data, 1);
    },

    subString(data) {
      if (data.length > 40) {
        return `${data.substring(0, 40)} ...`;
      } else {
        return data;
      }
    },
    checkViewFile(item) {
      console.log("item", item);

      window.open(this.showFileData(item.filename), "_blank");
    },
    showFileData(data) {
      console.log("data", data);
      return `${this.$config.serverURL}/downloads/${data}`;
    },
  },
  watch: {
    variableGlobalFormOject() {
      if (this.formTakeOjet) {
        this.insertTextFormObjet(this.variableGlobalFormOject.id);
      }
    },
    variableGlobalForm() {
      if (this.quillEditorForm.selection) {
        this.insertTextForm(this.variableGlobalForm.id);
      }
    },
  },
};
</script>
